import {
  Box,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { BoxWrapper } from "../../components/ui/BoxWrapper";
import { checkRole } from "../../utils/authHelper";

const AdminToolsPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks = [{ href: "/tools", title: "Tools" }];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !checkRole(accessToken, "access", "adminTools") &&
    !checkRole(accessToken, "access", "coachesTools")
  ) {
    return null;
  }

  return (
    <VStack w="full">
      {checkRole(accessToken, "access", "adminTools") && (
        <>
          <Heading as="h2" size="xl" mb={4}>
            Admin Tools
          </Heading>
          <SimpleGrid
            columns={[1, 1, 3, 3]}
            columnGap={5}
            rowGap={5}
            w={"full"}
          >
            <AdminToolsItem
              title={"Debug"}
              text={"Debug Page, useful for giving information to Jon!"}
              link={"/debug"}
            />
            <AdminToolsItem
              title={"User Lookup"}
              text={"Lookup a user to check their status."}
              link={"/admin/user/lookup"}
            />
          </SimpleGrid>
        </>
      )}
      {checkRole(accessToken, "access", "coachesTools") && (
        <>
          <Heading as="h2" size="xl" mb={4}>
            Coaches Tools
          </Heading>
          <SimpleGrid
            columns={[1, 1, 3, 3]}
            columnGap={5}
            rowGap={5}
            w={"full"}
          >
            <AdminToolsItem
              title={"Athlete Widgets"}
              text={
                "Configure you 'Athlete Widgets' (formerly 'Affiliate Widgets')."
              }
              link={"/affiliate/widgets"}
            />
            <AdminToolsItem
              title={"Athletes"}
              text={
                "Manage athletes linked to you as coach either through the affiliate program or invitations."
              }
              link={"/account/coachathletes"}
            />
          </SimpleGrid>
        </>
      )}
    </VStack>
  );
};

const AdminToolsItem = (props: any) => {
  const navigate = useNavigate();
  const iconColor = useColorModeValue("ssNeonOrangeDark", "ssNeonOrangeLight");

  return (
    <Box
      cursor={props.link ? "pointer" : "default"}
      onClick={() =>
        props.link ? navigate(props.link, { replace: false }) : null
      }
      opacity={props.link ? 1 : 0.5}
    >
      <BoxWrapper>
        <HStack w={"full"}>
          <VStack w={"full"} alignItems={"flex-start"}>
            <Heading size={"md"}>{props.title}</Heading>
            <Text>{props.text}</Text>
          </VStack>
          {props.link && (
            <Icon h={50} w={50} color={iconColor} as={FiArrowRightCircle} />
          )}
        </HStack>
      </BoxWrapper>
    </Box>
  );
};

export { AdminToolsPage };
