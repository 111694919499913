import {
  BoxProps,
  Button,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { billing, billingSubscription } from "../../../../types/billing";
import { SingleValueDisplay } from "../../../ui/SingleValueDisplay";
import { TrialDetails } from "../TrialDetails";

interface SubscriptionDetailsStripeProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsStripe: React.FC<SubscriptionDetailsStripeProps> = ({
  billing,
}) => {
  const subscription: billingSubscription =
    billing.subscription as billingSubscription;

  return (
    <VStack w="full" alignItems="start">
      <VStack w="full" alignItems="flex-start">
        {billing.subscription && (
          <>
            <Heading as="h3" size="md">
              You have a '{subscription.product.name}' subscription billed{" "}
              {subscription.product.price.interval === "year"
                ? "annually"
                : "monthly"}
            </Heading>
            <Text>{billing.subscriptionDescription}</Text>
            <SimpleGrid
              w="full"
              columns={[1, 1, 3, 3]}
              columnGap={2}
              rowGap={2}
            >
              <SingleValueDisplay
                value={subscription.customer}
                label="Customer"
              />
              <SingleValueDisplay
                value={subscription.id}
                label="Subscription"
              />
              <SingleValueDisplay
                value={
                  subscription.cancelled
                    ? "CANCELLED"
                    : new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(subscription.currentPeriodEnd))
                }
                label="Billing Period End Date"
              />
            </SimpleGrid>
          </>
        )}
        <TrialDetails trial={billing.trial} />
      </VStack>
      <Heading as="h3" size="md">
        Manage Your Subscription & Payment Details
      </Heading>
      <Text>
        Swim Smooth do not store or manage any of your payment details. This is
        all handled by our payment provider Stripe. Click the button below to
        access the secure Stripe portal to manage you subscriptions and payment
        methods. When asked to enter your email address you will need to use the
        email address you used when you created your subscription.
      </Text>
      <SimpleGrid columns={3} w={"full"} mt={5}>
        <Button
          variant="success"
          px={10}
          onClick={() => {
            window.open(process.env.REACT_APP_STRIPE_PORTAL)?.focus();
          }}
        >
          Manage Subscription
        </Button>
        <Spacer />
        <Button
          variant="warning"
          px={10}
          onClick={() => {
            console.log("Bob");
          }}
        >
          Cancel Subscription
        </Button>
      </SimpleGrid>
    </VStack>
  );
};

export { SubscriptionDetailsStripe };
