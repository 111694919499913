import {
  Button,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { NotAuthorised } from "../../components/generic/NotAuthorised";
import { CustomToast } from "../../components/ui/CustomToast";
import { checkRole } from "../../utils/authHelper";
import { createErrorMessage, createToast } from "../../utils/toastHelper";

type FormData = {
  fit: File | null;
};

const UploadFitPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const inputFile = useRef<HTMLInputElement | null>(null);
  const toast = useToast();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    defaultValues: { fit: null },
    mode: "all",
  });

  if (!checkRole(accessToken, "uploadFit", "activity")) {
    return <NotAuthorised size="full" functionText="upload FIT files" />;
  }

  const handleUpload = async (data: FormData) => {
    toast.closeAll();
    if (!data.fit) return; // Prevent processing null files
    try {
      const formData = new FormData();
      formData.append("fit", data.fit, data.fit.name);

      await axios.post(
        `${process.env.REACT_APP_API_ROOT_URL}files/activity/fit`,
        formData
      );

      createToast(toast, (props: any) => (
        <CustomToast
          title="FIT File Upload"
          status="Success"
          toast={toast}
          toastId={Number(props.id)} // Ensure proper type
        >
          <Text>FIT file uploaded, it may take some time to process.</Text>
        </CustomToast>
      ));

      if (inputFile.current) {
        inputFile.current.value = "";
      }
      reset();
    } catch (error) {
      createToast(toast, (props: any) => (
        <CustomToast
          title="FIT File Upload"
          status="Error"
          toast={toast}
          toastId={Number(props.id)} // Ensure proper type
        >
          <Text>{createErrorMessage(error)}</Text>
        </CustomToast>
      ));

      if (inputFile.current) {
        inputFile.current.value = "";
      }
      reset();
    }
  };

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Upload FIT File
      </Heading>
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={4}
        as="form"
        onSubmit={handleSubmit(handleUpload)}
        w="full"
      >
        <GridItem colSpan={5}>
          <FormControl>
            <Controller
              control={control}
              name="fit"
              rules={{
                required: "A file is required",
                validate: (file: File | null) =>
                  (file && file.name.endsWith(".fit")) ||
                  "Only .fit files are allowed",
              }}
              render={({ field: { onChange, onBlur }, fieldState }) => (
                <>
                  <Input
                    type="file"
                    accept=".fit"
                    onChange={(e) => {
                      const file = e.target.files?.[0] || null;
                      onChange(file); // Pass file to react-hook-form
                    }}
                    onBlur={onBlur}
                    ref={inputFile}
                  />
                  {fieldState.error && (
                    <Text color="red.500" fontSize="sm">
                      {fieldState.error.message}
                    </Text>
                  )}
                </>
              )}
            />
            <Text mt={2} fontSize="sm" color="gray.600">
              The uploaded file must be a FIT file, under 5Mb and conforming to
              the FIT specification.
            </Text>
          </FormControl>
        </GridItem>
        <Button
          isDisabled={!isValid}
          isLoading={isSubmitting}
          type="submit"
          colorScheme="blue"
        >
          Upload
        </Button>
      </Grid>
    </VStack>
  );
};

export { UploadFitPage };
