import {
  Box,
  BoxProps,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { GiPathDistance } from "react-icons/gi";
import { IoSpeedometerOutline, IoTimerOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { createCompletion } from "../../DataAccess/completions";
import { updateTrainingTimelineState } from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { useAppDispatch } from "../../app/hooks";
import { activity } from "../../types/activity";
import {
  formatDuration,
  formatPace,
  outputActivityDate,
} from "../../utils/dateHelper";
import { ActivityLinkedIcon } from "./ActivityLinkedIcon";
import { ActivityLocationType } from "./ActivityLocationType";
import { ActivityProvider } from "./ActivityProvider";
import { PacingScoreGraph } from "./PacingScoreGraph";

interface ActivityListItemForLinkingItemProps extends BoxProps {
  activity: activity;
  userId: string;
  sessionId: string;
  goalSessionId: string;
  targetDistance: number | null;
}

const ActivityListItemForLinking: React.FC<
  ActivityListItemForLinkingItemProps
> = ({ activity, userId, sessionId, goalSessionId, targetDistance }) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const boxMatchMode = useColorModeValue(
    "ssBoxBackgroundMatchLight",
    "ssBoxBackgroundMatchDark"
  );

  const [linking, setLinking] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const match = activity.data.summary.distances.total === targetDistance;

  const linkHandler = async () => {
    setLinking(true);
    await createCompletion(userId, activity.id, sessionId, goalSessionId);
    dispatch(updateTrainingTimelineState(null));
    setLinking(false);
    navigate("/training", { replace: false });
  };

  return (
    <Box
      id={"ACTIVITY_" + activity.id}
      key={"ACTIVITY_" + activity.id}
      bg={match ? boxMatchMode : boxColorMode}
      borderRadius={10}
      borderWidth={match ? 3 : 0}
      borderColor={match ? "green" : boxColorMode}
      mb={2}
      w="full"
      cursor="pointer"
    >
      <HStack>
        <Image
          src={activity.data.summary.images.list}
          h={140}
          w={140}
          borderLeftRadius={10}
          fit="cover"
        />
        <VStack align="flex-start" p={2} w="full">
          <Box>
            <Text fontSize="xs">
              <b>{outputActivityDate(activity.data.summary.dates)}</b>
            </Text>
            <Heading size="md" as="h3">
              {activity.data.name}
            </Heading>
            <Box>
              {activity.data.location.type === "Pool" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Pool"}{" "}
                  ({activity.data.location.lapLength}
                  {activity.data.location.lapUnit})
                </Text>
              )}
              {activity.data.location.type === "Open" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Open Water"}
                </Text>
              )}
              {activity.data.location.type === "Endless" && (
                <Text fontSize="sm">
                  {activity.data.location.name
                    ? activity.data.location.name
                    : "Endless Pool"}
                </Text>
              )}
            </Box>
          </Box>
          <Grid templateColumns="repeat(4, 0.5fr)" w="full">
            <GridItem>
              <Tooltip hasArrow label="Total Distance">
                <span>
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    viewBox="0 0 100 100"
                    mr={2}
                    as={GiPathDistance}
                  />
                  <Box display="inline-block" position="relative" top="-6px">
                    {activity.data.summary.distances.total}
                    {activity.data.location.lapUnit}
                  </Box>
                </span>
              </Tooltip>
            </GridItem>
            <GridItem>
              <Tooltip hasArrow label="Total Time">
                <span>
                  <Icon h={6} w={6} color="grey" mr={2} as={IoTimerOutline} />
                  <Box display="inline-block" position="relative" top="-6px">
                    {formatDuration(activity.data.summary.timings.total)}
                  </Box>
                </span>
              </Tooltip>
            </GridItem>
            <GridItem mr={10} colSpan={2}>
              <Tooltip hasArrow label="Average Pace">
                <span>
                  <Icon
                    h={6}
                    w={6}
                    color="grey"
                    mr={2}
                    as={IoSpeedometerOutline}
                  />
                  <Box display="inline-block" position="relative" top="-6px">
                    {formatPace(activity.data.summary.pace.avg)} / 100
                    {activity.data.location.lapUnit}
                  </Box>
                </span>
              </Tooltip>
            </GridItem>
          </Grid>
        </VStack>
        <VStack p={0}>
          <ActivityProvider size={60} provider={activity.source.provider} />
        </VStack>
        <VStack p={0} pr={5}>
          <ActivityLocationType
            size={40}
            locationType={activity.data.location.type}
            timings={activity.data.summary.timings}
          />
          <ActivityLinkedIcon completion={activity.data.completion} />
          <PacingScoreGraph
            size={40}
            score={activity.data.summary.execution.score}
          />
        </VStack>
        <VStack p={0} pr={5}>
          <Button onClick={linkHandler} isDisabled={linking}>
            Link
          </Button>
        </VStack>
      </HStack>
    </Box>
  );
};

export { ActivityListItemForLinking };
