import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import fileDownload from "js-file-download";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { selectActivityState } from "../../app/features/activity/activitySlice";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectSessionState,
  updateSessionState,
} from "../../app/features/session/sessionSlice";
import { updateTrainingTimelineState } from "../../app/features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { SessionCarousel } from "../../components/sessions/SessionCarousel";
import { SetRepeat } from "../../components/sessions/SetRepeat";
import { StrokeIdentifier } from "../../components/sessions/StrokeIdentifier";
import { SwimDescription } from "../../components/sessions/SwimDescription";
import { SessionEquipmentIcon } from "../../components/svg/SessionStrokeIcon";
import Loading from "../../components/ui/Loading";
import { createCompletion } from "../../DataAccess/completions";
import { getSessionById } from "../../DataAccess/sessions";
import { content } from "../../types/core";
import { sessionDataSetSwimData } from "../../types/session";
import { checkLevelInArray, checkRole } from "../../utils/authHelper";
import { translateEquipment } from "../../utils/equipmentHelper";
import { createToast } from "../../utils/toastHelper";
import { CustomToast } from "../ui/CustomToast";
import { SessionScaler } from "./SessionScaler";

interface SessionDetailProps {
  sessionId: string;
  targetDistance?: number;
  requestedDistance?: number;
}

const SessionDetail: React.FC<SessionDetailProps> = ({
  sessionId,
  targetDistance: targetDistanceParam,
  requestedDistance: requestedDistanceParam,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const [search] = useSearchParams();
  const linkActivityId = search.get("linkActivityId");
  const { session, targetDistance, requestedDistance } =
    useAppSelector(selectSessionState);
  const { activity } = useAppSelector(selectActivityState);

  const { user } = useAppSelector(selectUserState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [gettingPdf, setGettingPdf] = useState<boolean>(false);
  const [td, setTd] = useState<number | null>(null);
  const [isCompleting, setIsCompleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const completeRef = useRef(null);
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (session) {
      const breadcrumbLinks = [
        { href: "/sessions", title: "Sessions" },
        {
          href: "/sessions/" + session.data.type,
          title: session.data.typeName,
        },
        {
          href: "/sessions/" + session.id,
          title: session.data.name,
        },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    } else {
      const breadcrumbLinks = [
        { href: "/sessions", title: "Sessions" },
        { href: "/sessions", title: " - " },
        { href: "/sessions", title: " - " },
      ];
      setBreadcrumbLinks(breadcrumbLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    toast.closeAll();
    const getSessionLocal = async () => {
      if (
        checkRole(accessToken, "scale", "session") &&
        sessionId &&
        targetDistanceParam
      ) {
        const sessionResult = await getSessionById(
          sessionId,
          requestedDistanceParam ? requestedDistanceParam : targetDistanceParam
        );
        if (targetDistanceParam !== sessionResult.data.scaled.distance) {
          createToast(toast, (props: any) => {
            return (
              <CustomToast
                title={"Session Scaling"}
                status={"Info"}
                toast={toast}
                toastId={props.id}
              >
                <Text>
                  Unable to scale to {targetDistanceParam}
                  {user?.data.basic.defaultPool.lapUnit}, nearest possible
                  scaling {sessionResult.data.scaled.distance}
                  {user?.data.basic.defaultPool.lapUnit}.
                </Text>
              </CustomToast>
            );
          });
        }
        setTd(sessionResult.data.scaled.distance);
        dispatch(
          updateSessionState({
            session: sessionResult,
            targetDistance: targetDistanceParam
              ? targetDistanceParam
              : sessionResult.data.scaled.distance,
            requestedDistance: requestedDistanceParam
              ? requestedDistanceParam
              : sessionResult.data.scaled.distance,
          })
        );
        window.history.replaceState(
          null,
          "",
          "/sessions/id/" +
            sessionId +
            "/" +
            sessionResult.data.scaled.distance +
            "/" +
            requestedDistanceParam
            ? requestedDistanceParam
            : sessionResult.data.scaled.distance
        );
        setLoadingSession(false);
      } else if (sessionId) {
        const sessionResult = await getSessionById(sessionId);
        dispatch(
          updateSessionState({
            session: sessionResult,
            targetDistance: sessionResult.data.scaled.distance,
            requestedDistance: sessionResult.data.scaled.distance,
          })
        );
        window.history.replaceState(
          null,
          "",
          "/sessions/id/" +
            sessionId +
            "/" +
            sessionResult.data.scaled.distance +
            "/" +
            sessionResult.data.scaled.distance
        );
        setLoadingSession(false);
      }
    };
    getSessionLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, targetDistanceParam, requestedDistanceParam]);

  const getSessionPdf = useCallback(
    async (mode: number) => {
      if (session) {
        setGettingPdf(true);
        try {
          let distance = requestedDistance ? requestedDistance : targetDistance;
          let url =
            process.env.REACT_APP_API_ROOT_URL + "sessions/print/" + session.id;
          url += "?targetdistance=" + distance;
          url += "&mode=" + mode;
          url += "&t=" + new Date().getTime();
          const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/pdf",
            },
            responseType: "blob",
          });
          const pdfData = new Blob([res.data], { type: "application/pdf" });
          fileDownload(
            pdfData,
            session.data.name + "_" + session.data.scaled.distance + ".pdf"
          );
          setGettingPdf(false);
        } catch (error: any) {
          console.log(error);
          let errorMessage =
            "Unable to get session PDF, please contact support.";
          try {
            const serverError = JSON.parse(await error.response.data.text());
            console.log(serverError);
            errorMessage = serverError.messages[0].message.user;
          } catch (error) {
            // Nothing Doing
          }
          console.log();
          setGettingPdf(false);
          createToast(toast, (props: any) => {
            return (
              <CustomToast
                title={"Get Session PDF"}
                status={"Error"}
                toast={toast}
                toastId={props.id}
              >
                <Text>{errorMessage}</Text>
              </CustomToast>
            );
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestedDistanceParam, session, targetDistanceParam]
  );

  if (loadingSession) {
    return <Loading message="Loading Session" />;
  }

  if (!session) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  if (!user) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  if (!checkLevelInArray(accessToken, session.data.levels)) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  const markCompleteNewHandler = async (sessionId: string) => {
    setIsCompleting(true);
    setIsCompleting(false);
    navigate("/activities/create?sessionId=" + sessionId, { replace: false });
  };

  const markCompleteExistingHandler = async (sessionId: string) => {
    setIsCompleting(true);
    setIsCompleting(false);
    navigate("/activities/link?sessionId=" + sessionId, { replace: false });
  };

  const markCompleteExistingSelectedHandler = async (
    sessionId: string,
    activityId: string
  ) => {
    setIsCompleting(true);
    await createCompletion(user._id, activityId, sessionId, null);
    dispatch(updateTrainingTimelineState(null));
    setIsCompleting(false);
  };

  return (
    <VStack w="full">
      {linkActivityId && activity && linkActivityId === activity.id && (
        <Box
          bg="seagreen"
          w="full"
          px={5}
          py={2}
          alignItems="center"
          borderRadius={5}
          mb={4}
        >
          <Text>Linking to &#8220;{activity.data.name}&#8221;</Text>
        </Box>
      )}
      <HStack w="full" alignItems="flex-start">
        <VStack
          w={300}
          minWidth={300}
          maxWidth={300}
          h="100%"
          alignItems="flex-start"
        >
          <Center mb={5}>
            {session.data.images.has && (
              <Image
                src={session.data.images.data.preview}
                borderRadius={10}
                h={300}
                w={300}
                fit="cover"
                fallbackSrc="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
              />
            )}
            {!session.data.images.has && (
              <Image
                borderRadius={10}
                h={300}
                w={300}
                fit="cover"
                src="https://images.ctfassets.net/50b15ahactsg/Al70qWgtjQvAjRifFlBIq/35448e67bbf63873261153bf268d3764/Paul_Squad.jpg"
              />
            )}
          </Center>
          <Box>
            <Tooltip hasArrow label="Distance">
              <span>
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  viewBox="0 0 100 100"
                  mr={2}
                  as={GiPathDistance}
                />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.distance}
                  {session.data.unit}
                </Box>
              </span>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip hasArrow label="Expected Time">
              <span>
                <Icon h={5} w={5} color="grey" mr={2} as={IoTimerOutline} />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.time}
                </Box>
              </span>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip hasArrow label="sTSS">
              <span>
                <Icon h={5} w={5} color="grey" mr={2} as={MdModelTraining} />
                <Box display="inline-block" position="relative" top="-6px">
                  {session.data.scaled.stss
                    ? session.data.scaled.stss.toFixed(2)
                    : 0}
                </Box>
              </span>
            </Tooltip>
          </Box>
          {session.completions && session.completions > 0 && (
            <Box>
              <Tooltip hasArrow label="sTSS">
                <span>
                  <Icon
                    h={5}
                    w={5}
                    color="grey"
                    mr={2}
                    as={RiCheckDoubleFill}
                  />
                  <Box display="inline-block" position="relative" top="-6px">
                    Swum {session.completions} times
                  </Box>
                </span>
              </Tooltip>
            </Box>
          )}
          <Box pb={4}>
            <Tooltip hasArrow label="Intensity">
              <span>
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 0
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 1
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 2
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 3
                      ? intensityColorMode
                      : "grey"
                  }
                />
                <Icon
                  h={5}
                  w={5}
                  color="grey"
                  as={IoFlame}
                  fill={
                    session.data.scaled.intensity > 4
                      ? intensityColorMode
                      : "grey"
                  }
                />
              </span>
            </Tooltip>
          </Box>
          {checkRole(accessToken, "scale", "session") && (
            <Box
              bg={boxColorMode}
              borderRadius={10}
              pt={10}
              px={10}
              pb={8}
              w="full"
            >
              <SessionScaler
                sessionId={sessionId}
                session={session}
                user={user}
                disabled={false}
                td={td}
                setTd={setTd}
                setLoadingSession={setLoadingSession}
              />
            </Box>
          )}
          {!checkRole(accessToken, "scale", "session") && (
            <>
              <Box
                bg={boxColorMode}
                borderRadius={10}
                pt={10}
                px={10}
                pb={4}
                w="full"
              >
                <SessionScaler
                  sessionId={sessionId}
                  session={session}
                  user={user}
                  disabled={true}
                  td={td}
                  setTd={setTd}
                  setLoadingSession={setLoadingSession}
                />
                <Center>
                  <Text color={intensityColorMode} fontSize="xs" pt={6}>
                    Subscribe to unlock session scaling.
                  </Text>
                </Center>
              </Box>
            </>
          )}
          {!checkRole(accessToken, "scale", "session") && (
            <>
              <Box
                bg={boxColorMode}
                borderRadius={10}
                pt={10}
                px={10}
                pb={4}
                w="full"
              >
                <SessionScaler
                  sessionId={sessionId}
                  session={session}
                  user={user}
                  disabled={true}
                  td={td}
                  setTd={setTd}
                  setLoadingSession={setLoadingSession}
                />
                <Center>
                  <Text color={intensityColorMode} fontSize="xs" pt={6}>
                    Subscribe to unlock session scaling.
                  </Text>
                </Center>
              </Box>
            </>
          )}
          <Box bg={boxColorMode} borderRadius={10} p={2} w="full">
            <VStack>
              <>
                <Button
                  variant="success"
                  px={10}
                  isLoading={isCompleting}
                  onClick={onOpen}
                  w="full"
                >
                  {linkActivityId && activity && linkActivityId === activity.id
                    ? "Link to Activity"
                    : "Log Session"}
                </Button>
                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={completeRef}
                  onClose={onClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {linkActivityId &&
                        activity &&
                        linkActivityId === activity.id
                          ? "Link to Activity"
                          : "Log Session"}
                      </AlertDialogHeader>
                      <AlertDialogBody>
                        {(!linkActivityId ||
                          !activity ||
                          linkActivityId !== activity.id) && (
                          <VStack w="full">
                            <Text mb={4}>
                              Would you like to link this session to existing
                              activity, from a wearable, or create a new manual
                              activity?
                            </Text>
                            <Button
                              w="full"
                              variant="success"
                              px={20}
                              isLoading={isCompleting}
                              onClick={() =>
                                markCompleteExistingHandler(session.id)
                              }
                              ml={3}
                            >
                              Link to Existing Activity
                            </Button>
                            <Button
                              w="full"
                              variant="success"
                              px={20}
                              isLoading={isCompleting}
                              onClick={() => {
                                markCompleteNewHandler(session.id);
                              }}
                              ml={3}
                            >
                              Create New Activity
                            </Button>
                          </VStack>
                        )}
                        {linkActivityId &&
                          activity &&
                          linkActivityId === activity.id && (
                            <VStack w="full">
                              <Text mb={4}>
                                Would you like to link this session to &#8220;
                                {activity.data.name}&#8221;
                              </Text>
                              <Button
                                w="full"
                                variant="success"
                                px={20}
                                isLoading={isCompleting}
                                onClick={() =>
                                  markCompleteExistingSelectedHandler(
                                    session.id,
                                    linkActivityId
                                  )
                                }
                                ml={3}
                              >
                                Link Session to Activity
                              </Button>
                            </VStack>
                          )}
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button w="full" ref={completeRef} onClick={onClose}>
                          {linkActivityId &&
                          activity &&
                          linkActivityId === activity.id
                            ? "Don't Link Session"
                            : "Don't Log Session"}
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
              {checkRole(accessToken, "pdf", "session") && (
                <Menu>
                  <MenuButton
                    w="full"
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    isLoading={gettingPdf}
                  >
                    Get PDF
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        getSessionPdf(0);
                      }}
                    >
                      Standard
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        getSessionPdf(1);
                      }}
                    >
                      Larg(er) Print
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        getSessionPdf(2);
                      }}
                    >
                      Minimal
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
              {!checkRole(accessToken, "pdf", "session") && (
                <VStack>
                  <Button w="full" isDisabled>
                    Get PDF
                  </Button>
                  <Center>
                    <Text color={intensityColorMode} fontSize="xs" pt={2}>
                      Subscribe to unlock logging and PDF export.
                    </Text>
                  </Center>
                </VStack>
              )}
            </VStack>
          </Box>
          {!checkRole(accessToken, "pdf", "session") && (
            <Box bg={boxColorMode} borderRadius={10} p={2} w="full">
              <VStack>
                <Button w="full" isDisabled>
                  Log Activity
                </Button>
                <Button w="full" isDisabled>
                  Get PDF
                </Button>
              </VStack>
              <Center>
                <Text color={intensityColorMode} fontSize="xs" pt={2}>
                  Subscribe to unlock logging and PDF export.
                </Text>
              </Center>
            </Box>
          )}
          {session.data.briefing.length > 0 && (
            <>
              <Heading as="h4" size="xs">
                Session Briefing
              </Heading>
              {session.data.briefing.map((p: any, briefingIndex: number) => {
                return (
                  <Text mb={2} key={briefingIndex}>
                    {p.data}
                  </Text>
                );
              })}
            </>
          )}
          {session.data.expect.length > 0 && (
            <>
              <Heading as="h4" size="xs">
                What to Expect
              </Heading>
              {session.data.expect.map((e: any, expectIndex: number) => {
                return (
                  <Tag mb={2} key={expectIndex}>
                    {e}
                  </Tag>
                );
              })}
            </>
          )}
          {session.data.equipment.has &&
            session.data.equipment.data.length > 0 && (
              <>
                <Heading as="h4" size="xs">
                  Equipment
                </Heading>
                {session.data.equipment.data.map(
                  (e: any, equipmentIndex: number) => {
                    return (
                      <Tag mb={2} key={equipmentIndex}>
                        <SessionEquipmentIcon icon={e} />
                        <TagLabel>{translateEquipment(e)}</TagLabel>
                      </Tag>
                    );
                  }
                )}
              </>
            )}
        </VStack>
        <VStack w={980} h="100%" pl={10}>
          <Heading as="h2" size="xl" mb={4}>
            {session.data.name}
          </Heading>
          {session.data.sets.map((set: any, setIndex: number) => {
            return (
              <VStack pb={5} w="full" key={setIndex}>
                <Heading as="h3" size="small">
                  {set.title}
                </Heading>
                {set.intro.has &&
                  set.intro.data.map((t: content, introIndex: number) => {
                    return <Text key={introIndex}>{t.data}</Text>;
                  })}
                <SetRepeat repetitions={set.repetitions}>
                  <Box px={1} py={0} w="full">
                    {set.swims.map((swim: any, swimIndex: number) => {
                      if (swim.type === "rest") {
                        const data: string = swim.data as string;
                        return (
                          <Box w="full" mb={2} key={swimIndex}>
                            <Center>
                              <Text color="grey">{data}</Text>
                            </Center>
                          </Box>
                        );
                      }
                      const data: sessionDataSetSwimData =
                        swim.data as sessionDataSetSwimData;
                      if (data.expanded.has) {
                        return (
                          <Accordion
                            allowToggle
                            border="0px"
                            borderColor="transparent"
                            w="full"
                            alignItems="flex-start"
                            bg={boxColorMode}
                            borderRadius={10}
                            mb={2}
                            key={swimIndex}
                          >
                            <AccordionItem w="full" alignItems="flex-start">
                              <AccordionButton
                                w="full"
                                alignItems="flex-start"
                                p={0}
                              >
                                <HStack w="full" alignItems="flex-stretch">
                                  <StrokeIdentifier icon={data.icon} />
                                  <Box w="full" textAlign="left" pt={2}>
                                    <b>
                                      {data.distance} {data.title} {data.rest}
                                    </b>
                                    <br />
                                    {data.text.has ? (
                                      <SwimDescription text={data.text} />
                                    ) : null}
                                  </Box>
                                  <AccordionIcon w={10} />
                                </HStack>
                              </AccordionButton>
                              <AccordionPanel>
                                {data.expanded.data.type === "carousel" && (
                                  <SessionCarousel
                                    carousel={data.expanded.data}
                                  />
                                )}
                                {data.expanded.data.type !== "carousel" && (
                                  <Text>
                                    {JSON.stringify(data.expanded.data)}
                                  </Text>
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        );
                      }
                      return (
                        <Box
                          bg={boxColorMode}
                          borderRadius={10}
                          w="full"
                          mb={2}
                          key={swimIndex}
                        >
                          <HStack>
                            <StrokeIdentifier icon={data.icon} />
                            <VStack w="full" alignItems="flex-start">
                              <Box w="full">
                                <b>
                                  {data.distance} {data.title} {data.rest}
                                </b>
                                <br />
                                {data.text.has ? (
                                  <SwimDescription text={data.text} />
                                ) : null}
                              </Box>
                            </VStack>
                          </HStack>
                        </Box>
                      );
                    })}
                  </Box>
                </SetRepeat>
              </VStack>
            );
          })}
        </VStack>
      </HStack>
    </VStack>
  );
};

export { SessionDetail };
