import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  getActivities,
  updateActivityManual,
  updateActivityWearable,
} from "../../DataAccess/activities";
import { updateActivitiesState } from "../../app/features/activity/activitiesSlice";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  checkPaceAgainstCss,
  getManualActivitySTSS,
} from "../../utils/cssHelper";
import {
  formatPace,
  getActivityStartDate,
  splitDuration,
} from "../../utils/dateHelper";
import { PoolLengthSelector, PoolUnitSelector } from "../ui/DropDowns";
import { ActivityLocationGroup } from "../ui/FormControls";
import { HtmlEditor } from "../ui/HtmlEditor";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";

import "react-datepicker/dist/react-datepicker.css";
import { getCssCurrent } from "../../DataAccess/css";
import {
  selectActivityState,
  updateActivityState,
} from "../../app/features/activity/activitySlice";
import { cssEntry } from "../../types/css";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface ActivityEditProps extends BoxProps {}

const ActivityEdit: React.FC<ActivityEditProps> = () => {
  const toast = useToast();
  const [paceIssue, setPaceIssue] = useState<any>({
    removeLap: false,
    tooFast: false,
    tooSlow: false,
  });

  const { user } = useAppSelector(selectUserState);

  const { activity } = useAppSelector(selectActivityState);
  const [cssEntry, setCssEntry] = useState<cssEntry | null>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const today = new Date();

  const {
    control: controlBasic,
    watch: watchBasic,
    setValue: setValueBasic,
    trigger: triggerBasic,
    handleSubmit: handleSubmitBasic,
    formState: {
      errors: errorsBasic,
      isSubmitting: isSubmittingBasic,
      isValid: isValidBasic,
    },
  } = useForm({
    defaultValues: {
      name: "",
      locationType: "Pool",
      locationName: "",
      lapLength: 50,
      distance: 0,
      distanceUnit: "m",
      durationHours: 0,
      durationMinutes: 0,
      durationSeconds: 0,
      duration: 0,
      startDate: today,
      notes: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (activity) {
      const startDate = getActivityStartDate(activity.data.summary.dates);
      const duration = splitDuration(activity.data.summary.timings.total);
      setValueBasic("name", activity.data.name);
      setValueBasic("locationType", activity.data.location.type);
      setValueBasic("locationName", activity.data.location.name);
      setValueBasic("lapLength", activity.data.location.lapLength);
      setValueBasic("distance", activity.data.summary.distances.total);
      setValueBasic("distanceUnit", activity.data.location.lapUnit);
      setValueBasic("duration", activity.data.summary.timings.total);
      setValueBasic("durationHours", duration.hrs);
      setValueBasic("durationMinutes", duration.min);
      setValueBasic("durationSeconds", duration.sec);
      setValueBasic("startDate", startDate);
      setValueBasic("notes", activity.data.comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const watchLocationType = watchBasic("locationType", "Pool");
  const watchDistance = watchBasic("distance", 0);
  const watchDistanceUnit = watchBasic("distanceUnit", "");
  const watchLapLength = watchBasic("lapLength", 0);
  const watchDurationHours = watchBasic("durationHours", 0);
  const watchDurationMinutes = watchBasic("durationMinutes", 0);
  const watchDurationSeconds = watchBasic("durationSeconds", 0);
  const watchDuration = watchBasic("duration", 0);

  useEffect(() => {
    const getCssCurrentLocal = async () => {
      setCssEntry(await getCssCurrent());
    };
    getCssCurrentLocal();
  }, []);

  useEffect(() => {
    const hours: number = watchDurationHours * 60 * 60;
    const minutes: number = watchDurationMinutes * 60;
    const seconds: number = watchDurationSeconds * 1;
    const duration: number = hours + minutes + seconds;
    setValueBasic("duration", duration);
    triggerBasic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDurationHours, watchDurationMinutes, watchDurationSeconds]);

  useEffect(() => {
    if (cssEntry) {
      setPaceIssue(
        checkPaceAgainstCss(
          watchDistance,
          watchDistanceUnit,
          watchDuration,
          cssEntry
        )
      );
    }
  }, [cssEntry, watchDistance, watchDistanceUnit, watchDuration]);

  if (!user || !cssEntry || !activity) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Create Activity
        </Heading>
        <Loading message={"Loading Activity"} />
      </VStack>
    );
  }

  const handleBasic = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      // Apply timezone to date
      const tz = Intl.DateTimeFormat().resolvedOptions();
      let updatedActivity;
      if (activity.source.provider === "MANUAL") {
        updatedActivity = await updateActivityManual(activity.id, {
          name: data.name,
          location: {
            name: data.locationName,
            type: data.locationType,
            lapLength: parseInt(data.lapLength, 10),
            lapUnit: data.distanceUnit,
          },
          distance: parseInt(data.distance, 10),
          startDate: {
            date: data.startDate,
            offset: new Date().getTimezoneOffset().toString(),
            timezone: tz.timeZone,
          },
          duration: parseFloat(data.duration),
          comment: data.notes,
        });
      } else {
        updatedActivity = await updateActivityWearable(activity.id, {
          name: data.name,
          location: {
            name: data.locationName,
            type: activity.data.location.type,
            lapLength: activity.data.location.lapLength,
            lapUnit: activity.data.location.lapUnit,
          },
          comment: data.notes,
        });
      }
      // Reload the activities list
      const { activities, last } = await getActivities();
      dispatch(
        updateActivitiesState({
          lastActivity: last,
          lastLoad: activities.length,
          activities: activities,
        })
      );
      dispatch(updateActivityState(updatedActivity));
      // Go to the activity
      navigate("/activities/" + updatedActivity.id, { replace: false });
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Edit Activity"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to edit activity, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (activity.source.provider === "MANUAL") {
    return (
      <VStack alignItems="flex-start" w="full">
        <Heading as="h2" size="xl" mb={4}>
          Edit Activity
        </Heading>
        <VStack
          as="form"
          onSubmit={handleSubmitBasic(handleBasic)}
          w="full"
          alignItems="flex-start"
        >
          <FormControl pb={1}>
            <FormLabel>
              Activity Name
              {errorsBasic.name && (
                <span className="formError">{errorsBasic.name.message}</span>
              )}
            </FormLabel>
            <Controller
              control={controlBasic}
              name="name"
              render={({ field: { ref, ...restField } }) => (
                <Input {...restField} placeholder="" />
              )}
            />
          </FormControl>
          <HStack w="full" alignItems="flex-start">
            <VStack w="full" alignItems="flex-start" pr={2}>
              <FormControl pb={1}>
                <FormLabel>
                  Location
                  {errorsBasic.locationType && (
                    <span className="formError">
                      {errorsBasic.locationType.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={controlBasic}
                  name="locationType"
                  render={({ field: { ref, onChange, ...restField } }) => (
                    <ActivityLocationGroup
                      onChange={(value: string) => {
                        onChange(value);
                      }}
                      {...restField}
                    />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Location Name
                  {errorsBasic.locationName && (
                    <span className="formError">
                      {errorsBasic.locationName.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={controlBasic}
                  name="locationName"
                  render={({ field: { ref, ...restField } }) => (
                    <Input {...restField} placeholder="" />
                  )}
                />
              </FormControl>
              {watchLocationType === "Pool" && (
                <HStack w="full" alignItems="flex-start">
                  <FormControl pb={1}>
                    <FormLabel>
                      Pool Length ({watchDistanceUnit}){" "}
                      {errorsBasic.lapLength && (
                        <span className="formError">
                          {errorsBasic.lapLength.message}
                        </span>
                      )}
                    </FormLabel>
                    <Controller
                      control={controlBasic}
                      rules={{ required: "Pool Length is required" }}
                      name="lapLength"
                      render={({ field: { ref, onChange, ...restField } }) => (
                        <PoolLengthSelector
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(e)
                          }
                          {...restField}
                          placeholder=""
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
              )}
            </VStack>
            <VStack w="full" alignItems="flex-start" pl={2}>
              <HStack w="full" alignItems="flex-start">
                <FormControl pb={1}>
                  <FormLabel>
                    Distance
                    {errorsBasic.distance && (
                      <span className="formError">
                        {errorsBasic.distance.message}
                      </span>
                    )}
                  </FormLabel>
                  <HStack>
                    <Controller
                      control={controlBasic}
                      rules={{
                        required: "Distance is required",
                        min: {
                          value: 1,
                          message: "Distance must be greater than 0",
                        },
                      }}
                      name="distance"
                      render={({ field: { ref, ...restField } }) => (
                        <NumberInput {...restField} min={1} width="100%">
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />
                    <Controller
                      control={controlBasic}
                      rules={{ required: "Distance Unit is required" }}
                      name="distanceUnit"
                      render={({ field: { ref, onChange, ...restField } }) => (
                        <PoolUnitSelector
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(e)
                          }
                          {...restField}
                          placeholder=""
                        />
                      )}
                    />
                  </HStack>
                </FormControl>
              </HStack>
              <FormControl pb={1}>
                <FormLabel>
                  Start Time
                  {errorsBasic.startDate && (
                    <span className="formError">
                      {errorsBasic.startDate.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={controlBasic}
                  rules={{ required: "Email address is required" }}
                  name="startDate"
                  render={({
                    field: { value, ref, onChange, ...restField },
                  }) => (
                    <DatePicker
                      selected={new Date(value)}
                      onChange={onChange}
                      maxDate={new Date()}
                      showTimeSelect
                      dateFormat="do MMMM yyyy HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      calendarStartDay={1}
                      customInput={<Input />}
                      withPortal
                    />
                  )}
                />
              </FormControl>
              <FormControl pb={1}>
                <FormLabel>
                  Duration
                  {errorsBasic.duration && (
                    <span className="formError">
                      {errorsBasic.duration.message}
                    </span>
                  )}
                </FormLabel>
                <HStack>
                  <Controller
                    control={controlBasic}
                    rules={{ required: "Duration is required" }}
                    name="durationHours"
                    render={({ field: { ref, ...restField } }) => (
                      <InputGroup size="md">
                        <InputRightElement color="gray" mr={5}>
                          HH
                        </InputRightElement>
                        <NumberInput
                          {...restField}
                          min={0}
                          max={24}
                          step={1}
                          width="100%"
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                  <Controller
                    control={controlBasic}
                    rules={{ required: "Duration is required" }}
                    name="durationMinutes"
                    render={({ field: { ref, ...restField } }) => (
                      <InputGroup size="md">
                        <InputRightElement color="gray" mr={5}>
                          MM
                        </InputRightElement>
                        <NumberInput
                          {...restField}
                          min={0}
                          max={59}
                          step={1}
                          width="100%"
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                  <Controller
                    control={controlBasic}
                    rules={{ required: "Duration is required" }}
                    name="durationSeconds"
                    render={({ field: { ref, ...restField } }) => (
                      <InputGroup size="md">
                        <InputRightElement color="gray" mr={5}>
                          SS
                        </InputRightElement>
                        <NumberInput
                          {...restField}
                          min={0}
                          max={59}
                          step={1}
                          width="100%"
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                  <Controller
                    control={controlBasic}
                    rules={{
                      required: "Duration is required",
                      min: {
                        value: 1,
                        message: "Duration must be more than 0 seconds",
                      },
                    }}
                    name="duration"
                    render={({ field: { ref, ...restField } }) => (
                      <Input {...restField} type="hidden" />
                    )}
                  />
                </HStack>
              </FormControl>
            </VStack>
          </HStack>
          <HStack w="full" alignItems="flex-start">
            <VStack w="full" alignItems="flex-start" pr={2}>
              <FormControl pb={1}>
                <FormLabel>
                  Notes
                  {errorsBasic.notes && (
                    <span className="formError">
                      {errorsBasic.notes.message}
                    </span>
                  )}
                </FormLabel>
                <Controller
                  control={controlBasic}
                  name="notes"
                  render={({ field: { ref, onChange, ...restField } }) => (
                    <HtmlEditor
                      onChange={(value: string) => {
                        onChange(value);
                      }}
                      height={200}
                      {...restField}
                    />
                  )}
                />
              </FormControl>
            </VStack>
            <VStack w="full" alignItems="flex-start" pl={2}>
              <Text>Activity Summary</Text>
              <SimpleGrid columns={4} rowGap={5} columnGap={10}>
                {watchLocationType === "Pool" && watchLapLength > 0 && (
                  <SingleValueDisplay
                    label="Laps"
                    value={watchDistance / watchLapLength}
                  />
                )}
                <SingleValueWithUnitDisplay
                  label="Current CSS"
                  value={formatPace(
                    watchDistanceUnit === "y"
                      ? cssEntry.y.secondsPer100
                      : cssEntry.m.secondsPer100
                  )}
                  unit={"/100" + watchDistanceUnit}
                />
                {watchDistance > 0 && (
                  <SingleValueWithUnitDisplay
                    label="Avg. Pace"
                    value={formatPace((watchDuration / watchDistance) * 100)}
                    unit={"/100" + watchDistanceUnit}
                  />
                )}
                {watchDistance <= 0 && (
                  <SingleValueWithUnitDisplay
                    label="Avg. Pace"
                    value=" - "
                    unit={"/100" + watchDistanceUnit}
                  />
                )}
                {watchDistance > 0 && watchDuration > 0 && (
                  <SingleValueDisplay
                    label="sTSS"
                    value={getManualActivitySTSS(
                      watchDistance,
                      watchDistanceUnit,
                      watchDuration,
                      cssEntry
                    )}
                  />
                )}
                {(watchDistance <= 0 || watchDuration <= 0) && (
                  <SingleValueDisplay label="sTSS" value=" - " />
                )}
              </SimpleGrid>
              {paceIssue.removeLap && paceIssue.tooFast && (
                <Text color="red" pt={5}>
                  Your pacing appears to be <b>faster</b> than we would expect
                  given your current CSS pace. This could mean you have entered
                  the details of this activity incorrectly or it may be time to
                  tweak you CSS pace.
                </Text>
              )}
              {paceIssue.removeLap && paceIssue.tooSlow && (
                <Text color="red" pt={5}>
                  Your pacing appears to be <b>slower</b> than we would expect
                  given your current CSS pace. This could mean you have entered
                  the details of this activity incorrectly or it may be time to
                  tweak you CSS pace.
                </Text>
              )}
            </VStack>
          </HStack>
          <HStack w="full" alignItems="flex-start" pt={5}>
            <Button
              variant="warning"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Box w="full">{/* <!-- Spanner --> */}</Box>
            <Button
              isDisabled={!isValidBasic}
              isLoading={isSubmittingBasic}
              type="submit"
            >
              Save Activity
            </Button>
          </HStack>
        </VStack>
      </VStack>
    );
  }

  return (
    <VStack alignItems="flex-start" w="full">
      <Heading as="h2" size="xl" mb={4}>
        Edit Activity
      </Heading>
      <VStack
        as="form"
        onSubmit={handleSubmitBasic(handleBasic)}
        w="full"
        alignItems="flex-start"
      >
        <FormControl pb={1}>
          <FormLabel>
            Activity Name
            {errorsBasic.name && (
              <span className="formError">{errorsBasic.name.message}</span>
            )}
          </FormLabel>
          <Controller
            control={controlBasic}
            name="name"
            render={({ field: { ref, ...restField } }) => (
              <Input {...restField} placeholder="" />
            )}
          />
        </FormControl>
        <HStack w="full" alignItems="flex-start">
          <VStack w="full" alignItems="flex-start" pr={2}>
            <FormControl pb={1}>
              <FormLabel>
                Location Name
                {errorsBasic.locationName && (
                  <span className="formError">
                    {errorsBasic.locationName.message}
                  </span>
                )}
              </FormLabel>
              <Controller
                control={controlBasic}
                name="locationName"
                render={({ field: { ref, ...restField } }) => (
                  <Input {...restField} placeholder="" />
                )}
              />
            </FormControl>
          </VStack>
        </HStack>
        <HStack w="full" alignItems="flex-start">
          <VStack w="full" alignItems="flex-start" pr={2}>
            <FormControl pb={1}>
              <FormLabel>
                Notes
                {errorsBasic.notes && (
                  <span className="formError">{errorsBasic.notes.message}</span>
                )}
              </FormLabel>
              <Controller
                control={controlBasic}
                name="notes"
                render={({ field: { ref, onChange, ...restField } }) => (
                  <HtmlEditor
                    onChange={(value: string) => {
                      onChange(value);
                    }}
                    height={200}
                    {...restField}
                  />
                )}
              />
            </FormControl>
          </VStack>
        </HStack>
        <HStack w="full" alignItems="flex-start" pt={5}>
          <Button
            variant="warning"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Box w="full">{/* <!-- Spanner --> */}</Box>
          <Button
            isDisabled={!isValidBasic}
            isLoading={isSubmittingBasic}
            type="submit"
          >
            Save Activity
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};

export { ActivityEdit };
