import { BoxProps, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { selectUserState } from "../../../../app/features/user/userSlice";
import { useAppSelector } from "../../../../app/hooks";
import { breadcrumbLink } from "../../../../types/breadcrumb";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../../context/BreadcrumbContext";
import Loading from "../../../ui/Loading";

interface SubscribeStripeProps extends BoxProps {}

const StripePricingTable: React.FC<SubscribeStripeProps> = () => {
  const { user } = useAppSelector(selectUserState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const pricingTableId = useColorModeValue(
    process.env.REACT_APP_STRIPE_PT_LIGHT,
    process.env.REACT_APP_STRIPE_PT_DARK
  );

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({
      href: "/account/subscription",
      title: "Subscription",
    });
    breadcrumbLinks.push({
      href: "/account/subscription/subscribe",
      title: "Subscribe",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!user || !user._id || !user.data.basic.emailAddress) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack w="full">
      {React.createElement("stripe-pricing-table", {
        "pricing-table-id": pricingTableId,
        "publishable-key": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        style: { width: "100%" },
        "client-reference-id": user._id,
        "customer-email": user.data.basic.emailAddress,
      })}
      <Text mt={3} fontSize={"xx-small"}>
        {user.data.basic.firstName} / {user.data.basic.lastName} /{" "}
        {user.data.basic.emailAddress} / {user._id}
      </Text>
    </VStack>
  );
};

export { StripePricingTable };
