import { Text } from "@chakra-ui/react";
import { selectUserState } from "../../app/features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { StripePricingTable } from "../../components/account/subscription/stripe/StripePricingTable";
import Loading from "../../components/ui/Loading";

const AccountSubscribePage = () => {
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  // If we already have a subscription then we cannot subscribe
  if (
    user.data.billing.subscriptionStatus === "Active" ||
    user.data.billing.subscriptionStatus === "Cancelled"
  ) {
    return (
      <>
        <Text>Cannot subscribe - Update this!</Text>
        <Text>
          {user.data.basic.emailAddress}/{user._id}
        </Text>
      </>
    );
  }

  if (process.env.REACT_APP_BILLING_PLATFORM === "Stripe") {
    return <StripePricingTable />;
  }
  return <Loading message={"Billing Platform Unavailable"} />;
};

export { AccountSubscribePage };
