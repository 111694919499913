import {
  Button,
  Checkbox,
  FormControl,
  Heading,
  HStack,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  selectUserState,
  updateUserState,
} from "../../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateUserConsent } from "../../../DataAccess/users";
import { formatDateTime } from "../../../utils/dateHelper";
import { createToast } from "../../../utils/toastHelper";
import { CreateSupportTicket } from "../../ui/CreateSupportTicket";
import { CustomToast } from "../../ui/CustomToast";
import Loading from "../../ui/Loading";

const AccountSettingsOptIn = () => {
  const toast = useToast();
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();

  const {
    control: controlSignup,
    handleSubmit,
    setValue: setValueBasic,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      gdprRequired: false,
      gdprMailing: false,
      gdprMarketing: false,
    },
    mode: "all",
  });

  useEffect(() => {
    if (user && user.data.consent) {
      if (user.data.consent.required) {
        setValueBasic(
          "gdprRequired",
          user.data.consent.required.hasOwnProperty("consentGiven") &&
            user.data.consent.required.consentGiven !== "" &&
            (!user.data.consent.required.hasOwnProperty("consentRevoked") ||
              (user.data.consent.required.hasOwnProperty("consentRevoked") &&
                user.data.consent.required.consentRevoked === ""))
        );
      }
      if (user.data.consent.mailing) {
        setValueBasic(
          "gdprMailing",
          user.data.consent.mailing.hasOwnProperty("consentGiven") &&
            user.data.consent.mailing.consentGiven !== "" &&
            (!user.data.consent.mailing.hasOwnProperty("consentRevoked") ||
              (user.data.consent.mailing.hasOwnProperty("consentRevoked") &&
                user.data.consent.mailing.consentRevoked === ""))
        );
      }
      if (user.data.consent.marketing) {
        setValueBasic(
          "gdprMarketing",
          user.data.consent.marketing.hasOwnProperty("consentGiven") &&
            user.data.consent.marketing.consentGiven !== "" &&
            (!user.data.consent.marketing.hasOwnProperty("consentRevoked") ||
              (user.data.consent.marketing.hasOwnProperty("consentRevoked") &&
                user.data.consent.marketing.consentRevoked === ""))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSave = async (data: any): Promise<void> => {
    toast.closeAll();
    if (user) {
      try {
        const updatedUser = await updateUserConsent({
          gdprRequired: data.gdprRequired,
          gdprMailing: data.gdprMailing,
          gdprMarketing: data.gdprMarketing,
        });
        dispatch(updateUserState(updatedUser));
        setTimeout(function () {
          window.location.reload();
        }, 50);
      } catch (error: any) {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Unable to save opt out preferences"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to save opt out preferences, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  const requiredConsent = (): Boolean => {
    toast.closeAll();
    console.log("Required Consent");
    createToast(toast, (props: any) => {
      return (
        <CustomToast
          title={"Required Consent"}
          status={"Warning"}
          toast={toast}
          toastId={props.id}
        >
          <Text>
            This consent is required to use the Swim Smooth Guru. If you no
            longer consent you can 'Request Account Deletion' instead.
          </Text>
          <CreateSupportTicket />
        </CustomToast>
      );
    });
    return false;
  };

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(handleSave)}
      w="full"
      p={5}
      spacing={3}
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Opt Out Preferences
      </Heading>
      <Heading as="h2" size="sm">
        Required
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          rules={{ required: "You must agree!" }}
          name="gdprRequired"
          render={({ field: { value } }) => (
            <Checkbox
              spacing={8}
              isChecked={value}
              onChange={requiredConsent}
              size={"lg"}
            >
              <Text mb={2}>
                I agree to the <Button variant="link">terms of service</Button>{" "}
                and <Button variant="link">privacy policy</Button> and the
                collection and processing of my health data in order to give
                feedback on my swimming.
              </Text>
              {user.data.consent &&
                user.data.consent.required &&
                user.data.consent.required.consentGiven &&
                user.data.consent.required.consentGiven !== "" && (
                  <Text fontSize={"xs"}>
                    <b>Consent Given:</b>{" "}
                    {formatDateTime(user.data.consent.required.consentGiven)}
                    <br />
                    {user.data.consent.required.consentRevoked &&
                      user.data.consent.required.consentRevoked !== "" && (
                        <Text as={"span"}>
                          <b>Consent Revoked:</b>{" "}
                          {formatDateTime(
                            user.data.consent.required.consentRevoked
                          )}
                        </Text>
                      )}
                  </Text>
                )}
              {(!user.data.consent ||
                !user.data.consent.required ||
                !user.data.consent.required.consentGiven ||
                (user.data.consent.required.consentGiven &&
                  user.data.consent.required.consentGiven === "")) && (
                <Text fontSize={"xs"}>
                  <b>Consent Given:</b> Never
                </Text>
              )}
            </Checkbox>
          )}
        />
      </FormControl>
      <Heading as="h2" size="sm">
        Recommended
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          name="gdprMailing"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              spacing={8}
              isChecked={value}
              onChange={onChange}
              size={"lg"}
            >
              <Text mb={2}>
                I'm okay with my email address being added to the "Feel For The
                Water" by Swim Smooth blog and mailing list.
              </Text>
              {user.data.consent &&
                user.data.consent.mailing &&
                user.data.consent.mailing.consentGiven &&
                user.data.consent.mailing.consentGiven !== "" && (
                  <Text fontSize={"xs"}>
                    <b>Consent Given:</b>{" "}
                    {formatDateTime(user.data.consent.mailing.consentGiven)}
                    <br />
                    {user.data.consent.mailing.consentRevoked &&
                      user.data.consent.mailing.consentRevoked !== "" && (
                        <Text as={"span"}>
                          <b>Consent Revoked:</b>{" "}
                          {formatDateTime(
                            user.data.consent.mailing.consentRevoked
                          )}
                        </Text>
                      )}
                  </Text>
                )}
              {(!user.data.consent ||
                !user.data.consent.mailing ||
                !user.data.consent.mailing.consentGiven ||
                (user.data.consent.mailing.consentGiven &&
                  user.data.consent.mailing.consentGiven === "")) && (
                <Text fontSize={"xs"}>
                  <b>Consent Given:</b> Never
                </Text>
              )}
            </Checkbox>
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          name="gdprMarketing"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              spacing={8}
              isChecked={value}
              onChange={onChange}
              size={"lg"}
            >
              <Text mb={2}>
                I am happy to help Swim Smooth in its mission to democratise
                access to exceptional swimming content and coaching for swimmers
                worldwide by allowing the use of my data for marketing and
                advertising purposes, including the creation of custom and
                lookalike audiences on advertising platforms.
              </Text>
              {user.data.consent &&
                user.data.consent.marketing &&
                user.data.consent.marketing.consentGiven &&
                user.data.consent.marketing.consentGiven !== "" && (
                  <Text fontSize={"xs"}>
                    <b>Consent Given:</b>{" "}
                    {formatDateTime(user.data.consent.marketing.consentGiven)}
                    <br />
                    {user.data.consent.marketing.consentRevoked &&
                      user.data.consent.marketing.consentRevoked !== "" && (
                        <Text as={"span"}>
                          <b>Consent Revoked:</b>{" "}
                          {formatDateTime(
                            user.data.consent.marketing.consentRevoked
                          )}
                        </Text>
                      )}
                  </Text>
                )}
              {(!user.data.consent ||
                !user.data.consent.marketing ||
                !user.data.consent.marketing.consentGiven ||
                (user.data.consent.marketing.consentGiven &&
                  user.data.consent.marketing.consentGiven === "")) && (
                <Text fontSize={"xs"}>
                  <b>Consent Given:</b> Never
                </Text>
              )}
            </Checkbox>
          )}
        />
      </FormControl>
      <HStack w="full" pt={2}>
        <Spacer />
        <Button type="submit" isDisabled={!isDirty} isLoading={isSubmitting}>
          Save Opt Out Preferences
        </Button>
      </HStack>
    </VStack>
  );
};

export { AccountSettingsOptIn };
